@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');

body, html {
  font-family: 'Roboto', sans-serif;
}

.bg-purple-gradient {
  background: linear-gradient(272.88deg, #6F429A 0.32%, rgba(111, 66, 154, 0.8) 35.04%, rgba(111, 66, 154, 0.4) 72.74%, rgba(111, 66, 154, 0.66) 99.47%);
}

.btn-blue-purple-gradient {
  background: linear-gradient(96.52deg, #A92CD6 10.26%, #5EA8C6 79.64%);
  -webkit-text-fill-color: white;
}

.btn-danger, .btn-info, .btn-success, .btn-warning {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-light-rgb), var(--cui-text-opacity)) !important
}

.position-fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
}

.toast-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.form-label {
  font-weight: bold;
  margin-top: 1rem;
}

.container-lg header {
  padding: 0.5rem 1rem !important;
}

.mt-140px {
  margin-top: 140px;
}

.badge {
  font-size: 12px;
  line-height: 17px;
  padding: 4px 8px;
}
